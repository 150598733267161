*{
    padding: 0;
    margin: 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.h-full, .cursor-grab{
    height: 100% !important;
}
.font-fam{
    font-family: 'Domine', serif;
}

.grievance{
    padding: 1rem;
}

@media (min-width: 768px) {
    .grievance{
        padding: 2.25rem;
    }
}

