.btn-one {
  width: fit-content;
}

.bgCoc {
  background-image: url("https://files.yashdental.org/wp-content/uploads/2023/04/Policy.png");
}
.bgPolicy {
  background-image: url("https://files.yashdental.org/wp-content/uploads/2023/04/Research.png");
}

.bgMentor {
  background-image: url("https://files.yashdental.org/wp-content/uploads/2023/04/mentor.png");
}
.bgPTA {
  background-image: url("https://files.yashdental.org/wp-content/uploads/2023/04/pta-1.png");
}
.bgEgov {
  background-image: url("https://files.yashdental.org/wp-content/uploads/2023/04/Egover-1.png");
}
.bgPS {
  background-image: url("https://files.yashdental.org/wp-content/uploads/2023/04/PatientSafety.png");
}
.bgAntiRag {
  background-image: url("https://files.yashdental.org/wp-content/uploads/2023/04/AntiRag.png");
}
.bgParentsAss {
  background-image: url("https://files.yashdental.org/wp-content/uploads/2023/04/ParentsAssociation.png");
}
.bgHar {
  background-image: url("https://files.yashdental.org/wp-content/uploads/2023/04/HarCommittee.png");
}

.marginCss {
  margin: 1.25rem;
}

@media screen and (max-width: 768px) {
  .marginCss {
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
