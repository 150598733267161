.carosuel {
  background-color: #800000;
  padding-left: 3%;
  padding-right: 3%;
  text-align: center;
  
  
}
.carosuel-bg {
  border: #800000 solid 20px;
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.2);
}


