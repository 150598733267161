.headerSection {
  padding: 3% 8%;
  background-color: #00205b;
  color: white;
}

.inner-box {
  border-left: #ffd900 solid 0.3rem;
  padding: 2%;
  padding-left: 5%;
}

.inner-box2{
    padding: 2%; 
    background-image:
    linear-gradient(to left, transparent, rgba(0, 32, 91, 0.82) 58.32%),
    url('../../assets/images/attractive-female-doctor.png');
    height: 50vh;
    
    background-size: cover;
    color: white;
    padding: 20px;
}

@media only screen and (max-width: 767px){
  .inner-box2{
    display: none;
  }

  #bdsAdmission{
    padding: 3% !important;
  }
  
  #prosPectus{
    margin: 1.25rem;
  }
  
}

#prosPectus{
  margin: 0.5rem;
}

.admission-titles {
  font-family: "Domine";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: 0.08em;
  margin-bottom: 5%;
}

.admission-title {
  color: #ffd900;
  font-size: 3rem;
}

.for-bds {
  font-size: 1.3rem;
  margin-top: 1rem;
}

.admission-discription {
  font-family: "Domine";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.08em;
}


.gradinetStyle{

  background-image:
    linear-gradient(to left, transparent, rgba(128, 0, 0, 1)),
    url('https://files.yashdental.org/wp-content/uploads/2023/04/softwareApplication.jpg');
    height: 50vh;
    
    background-size: cover;
    color: white;
    padding: 20px;


}