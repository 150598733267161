.loading svg polyline {
    fill: none;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  
  .loading svg polyline#back {
    fill: none;
    stroke: #ff4d5033;
  }
  
  .loading svg polyline#front {
    fill: none;
    stroke: #ff4d4f;
    stroke-dasharray: 48, 144;
    stroke-dashoffset: 192;
    animation: dash_682 1.4s linear infinite;
  }
  
  @keyframes dash_682 {
    72.5% {
      opacity: 0;
    }
  
    to {
      stroke-dashoffset: 0;
    }
  }
  