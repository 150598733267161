.naacBg {
  background-image: url("../../assets//images/NAAC/bgimg.png");
  background-color: #800000;
  width: 100%;
  height: 55vh;
  background-size: cover;
  background-position: center;
}

.excel-main-card{
  height: 282.38px;
  width: 180px;
  background: url("../../assets/images/NAAC/excel.png") repeat-x;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
}


.app-body-sub-one {
  width: 480px;

  @media screen and (max-width: 400px) {
    width: 332px;
  }
}

.pdf-main-card {
  /* background-image: url("../../assets/images/NAAC/pdfImg.png"); */
  height: 282.38px;
  width: 180px;
  background: url("../../assets/images/NAAC/pdfImg.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  justify-content: center;
}
.pdfnametag {
  text-align: center;
  margin-bottom: 45px;
}
@media screen and (max-width: 768px) {
  .naacBg {
    height: 28vh;
    padding-left: 60px;
  }
}
@media screen and (max-width: 400px) {
  .container-two {
    padding-left: 0% !important;
  }
  .app-body-sub {
    flex: content;
    flex-wrap: wrap;
  }
}

.LockSection {
  height: 60vh !important;
  background-color: whitesmoke;
  height: 100%;
  text-align: center;
  padding: 10%;
  color: black;
}

#password {
  display: block;
  margin: auto;
}

.submitbtn {
  background: #800000;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  border-radius: 10px;
  color: white;
  min-width: 10rem;
  min-height: 5vh;
  margin-top: 2%;
}
